<template>
  <div class="flex flex-col items-center gap-y-4 select-none">
    <NuxtLink
      :to="blok?.url?.url ? blok.url.url : `/${locale}`"
      class="flex items-center justify-center h-[140px] w-[140px] md:h-[230px] md:w-[230px] rounded-full"
      :style="`background-color: ${circleColor}`"
      ><img
        :src="OptimizeStoryBlokImage(blok?.image?.filename)"
        class="h-[90px] w-[90px] md:h-[160px] md:w-[160px] object-contain p-2"
    /></NuxtLink>

    <p
      class="text-sm md:text-base font-medium text-center tracking-[0.02em] text-[#141719]"
    >
      {{ blok?.title }}
    </p>
  </div>
</template>

<script setup>
import { OptimizeStoryBlokImage } from '@/utils/helpers'
defineProps({
  blok: Object,
  circleColor: String,
})

const { locale } = useI18n()
</script>
